<script>
import { mapGetters } from "vuex";
import { wpService } from "@/services/wp";
import _merge from "lodash/merge";
import listingBox from "@/components/listing-box-new.vue";
import fieldMetric from "@/components/field-metric";
import pubYear from "@/components/publish-field-year";
export default {
  components: {
    listingBox,
    fieldMetric,
    pubYear,
  },
  props: {
    entry: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      editing: false,
      clonedValues: null,
      wanted: {
        ltg_cat: "Tipo",
        ltg_buildable: "Edificable",
        ltg_cond: "Condición",
        ltg_built: "Construcción",
        ltg_mts: "Solar/Terreno",
        ltg_room: "Habitaciones",
        ltg_bath: "Baños",
        ltg_park: "Parqueos",
        ltg_level: "Nivel/Piso",
        ltg_year: "Año",
      },
    };
  },
  created() {
    this.reset();
  },
  computed: {
    currentValues() {
      let currentValues = {};
      for (const key in this.wanted) {
        currentValues[key] = this.entry[key];
      }
      return currentValues;
    },
    ...mapGetters("config", [
      "listings_categories",
      "listings_conditions",
      "get_category",
      "get_condition",
    ]),
    category() {
      return this.get_category(this.entry.ltg_cat);
    },
    ltg_cond() {
      return this.get_condition(this.entry.ltg_cond);
    },
    canSubmit() {
      let canSubmit = {};
      for (const key in this.clonedValues) {
        if (this.clonedValues[key] !== this.currentValues[key])
          canSubmit[key] = this.clonedValues[key];
      }
      if (Object.keys(canSubmit).length) return canSubmit;
      return false;
    },
  },
  methods: {
    numericSelect(min = 0, max = 10, step = 1) {
      let values = [];
      for (let index = min; index <= max; index += step) {
        values.push(index);
      }
      return values;
    },
    reset() {
      this.clonedValues = _merge({}, this.currentValues);
    },
    submit() {
      let loading = this.$buefy.loading.open({ container: null });
      wpService.listings
        .patchSingle(this.entry.ltg_id, this.canSubmit)
        .then(() => {
          this.close();
          this.$buefy.toast.open({
            message: "¡Detalles actualizados!",
            type: "is-success",
          });
          loading.close();
        })
        .catch(err => {
          console.log(err);
          loading.close();
        });
    },
    close() {
      this.editing = false;
    },
  },
  watch: {
    editing() {
      this.reset();
    },
  },
};
</script>

<template>
  <listingBox
    title="Detalles"
    editable
    :editing="editing"
    @editing="editing = $event"
    hasSubmit
    :canSubmit="!!canSubmit"
    @submit="submit"
  >
    <b-message type="is-danger" v-if="!category">
      <b-icon icon="exclamation-circle" />
      <span><b>Requerido:</b> Especificar el tipo de propiedad.</span>
    </b-message>
    <b-message type="is-warning" v-if="!ltg_cond">
      <b-icon icon="eye" />
      <span><b>Recomendado: </b>Especificar la condición actual.</span>
    </b-message>

    <div class="readables">
      <div class="databoxes">
        <div class="field da--category">
          <span class="da__label">{{ wanted.ltg_cat }}</span>
          <span class="da__value">{{ category ? category.label : "" }}</span>
        </div>
        <div class="field da--ltg_cond">
          <span class="da__label">{{ wanted.ltg_cond }}</span>
          <span class="da__value">{{ ltg_cond ? ltg_cond.label : "" }}</span>
        </div>
        <div class="field da--ltg_buildable">
          <span class="da__label">{{ wanted.ltg_buildable }}</span>
          <span class="da__value">{{ entry.ltg_buildable ? "Si" : "No" }}</span>
        </div>
        <div class="field da--ltg_year">
          <span class="da__label">{{ wanted.ltg_year }}</span>
          <span class="da__value">{{ entry.ltg_year || "N/D" }}</span>
        </div>
        <div class="field da--ltg_built">
          <span class="da__label">{{ wanted.ltg_built }}</span>
          <span class="da__value">{{ entry.ltg_built || 0 }} m²</span>
        </div>
        <div class="field da--ltg_mts">
          <span class="da__label">{{ wanted.ltg_mts }}</span>
          <span class="da__value">{{ entry.ltg_mts || 0 }} m²</span>
        </div>
        <div class="field da--ltg_room">
          <span class="da__label">{{ wanted.ltg_room }}</span>
          <span class="da__value">{{ entry.ltg_room || 0 }}</span>
        </div>
        <div class="field da--ltg_bath">
          <span class="da__label">{{ wanted.ltg_bath }}</span>
          <span class="da__value">{{ entry.ltg_bath || 0 }}</span>
        </div>
        <div class="field da--ltg_park">
          <span class="da__label">{{ wanted.ltg_park }}</span>
          <span class="da__value">{{ entry.ltg_park || 0 }}</span>
        </div>
        <div class="field da--ltg_level">
          <span class="da__label">{{ wanted.ltg_level }}</span>
          <span class="da__value">{{ entry.ltg_level || "N/D" }}</span>
        </div>
      </div>
    </div>

    <s-mudal v-if="editing" :active="true" @close="close()">
      <div class="sitioCard">
        <div class="sitioCard__header">
          <div class="sitioCard__header__title">Detalles</div>
        </div>
        <div class="sitioCard__content">
          <div class="editables">
            <b-field :label="wanted.ltg_cat">
              <b-select
                v-model="clonedValues.ltg_cat"
                class="is-primary"
                placeholder="Seleccionar"
              >
                <option
                  v-for="(option, key) in listings_categories"
                  :value="key"
                  :key="key"
                  >{{ option.label }}</option
                >
              </b-select>
            </b-field>
            <b-field :label="wanted.ltg_cond">
              <b-select
                class="is-primary"
                placeholder="Seleccionar"
                v-model="clonedValues.ltg_cond"
              >
                <option
                  v-for="(option, key) in listings_conditions"
                  :value="key"
                  :key="key"
                  >{{ option.label }}</option
                >
              </b-select>
            </b-field>
            <b-field :label="wanted.ltg_buildable">
              <div class="control">
                <b-switch
                  v-model="clonedValues.ltg_buildable"
                  :rounded="false"
                  >{{ clonedValues.ltg_buildable ? "Si" : "No" }}</b-switch
                >
              </div>
            </b-field>
            <pubYear
              id="field--ltg_year"
              :label="wanted.ltg_year"
              inputID="pub-ltg_year"
              v-model="clonedValues.ltg_year"
            />
            <fieldMetric
              inputID="pub-ltg_built"
              v-model="clonedValues.ltg_built"
              :label="wanted.ltg_built"
            />
            <fieldMetric
              inputID="pub-ltg_mts"
              v-model="clonedValues.ltg_mts"
              :label="wanted.ltg_mts"
            />
            <b-field :label="wanted.ltg_room" label-for="pub-ltg_room">
              <b-select id="pub-ltg_room" v-model="clonedValues.ltg_room">
                <option
                  v-for="option in numericSelect(0, 99, 1)"
                  :value="option"
                  :key="option"
                  >{{ option }}</option
                >
              </b-select>
            </b-field>
            <b-field :label="wanted.ltg_bath" label-for="pub-ltg_bath">
              <b-select id="pub-ltg_bath" v-model="clonedValues.ltg_bath">
                <option
                  v-for="option in numericSelect(0, 99, 0.5)"
                  :value="option"
                  :key="option"
                  >{{ option }}</option
                >
              </b-select>
            </b-field>
            <b-field :label="wanted.ltg_park" label-for="pub-ltg_park">
              <b-select id="pub-ltg_park" v-model="clonedValues.ltg_park">
                <option
                  v-for="option in numericSelect(0, 99, 1)"
                  :value="option"
                  :key="option"
                  >{{ option }}</option
                >
              </b-select>
            </b-field>
            <b-field :label="wanted.ltg_level" label-for="pub-ltg_level">
              <b-select id="pub-ltg_level" v-model="clonedValues.ltg_level">
                <option
                  v-for="option in numericSelect(0, 99, 1)"
                  :value="option"
                  :key="option"
                  >{{ option }}</option
                >
              </b-select>
            </b-field>
          </div>
        </div>
        <div class="sitioCard__footer">
          <div class="sitioCard__footer__item">
            <b-button @click="editing = false">
              Cerrar
            </b-button>
          </div>
          <div class="sitioCard__footer__item">
            <b-button
              :class="{ 'is-primary': canSubmit }"
              :disabled="!canSubmit"
              @click="submit"
            >
              Guardar
            </b-button>
          </div>
        </div>
      </div>
    </s-mudal>
  </listingBox>
</template>

<style lang="scss" scoped>
.readables .databoxes,
.editables {
  display: grid;
  gap: 0.5rem;
  width: auto;
  // .field {
  //   flex: 0 0 50%;
  //   margin-bottom: 1rem;
  // }
  /deep/ .label,
  .da__label {
    flex: 0 0 7rem;
    width: 7rem;
  }
}
.field {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 0 !important;
  /deep/ .label,
  .da__label {
    margin-bottom: 0;
    padding-right: 1rem;
    // flex: 0 0 9rem;
    // width: 9rem;
    font-weight: 500;
  }
  .da__value {
    opacity: 0.8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-bottom: 2px solid $sitio1;
  }
  /deep/ .input,
  /deep/ .select {
    font-size: 0.9375em;
  }
  /deep/ .v-money {
    max-width: 8rem;
  }
}
@media (min-width: 608px) {
  .readables .databoxes,
  .editables {
    grid-template-columns: 1fr 1fr;
    // gap: 1rem;
    /deep/ .label,
    .da__label {
      // flex: 0 0 7rem;
      width: 7rem;
    }
  }
}
</style>
