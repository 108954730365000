<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    inputID: {
      type: String,
      default: "",
    },
    value: {
      default: null,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    availableYears() {
      let current = new Date().getFullYear();
      let diff = current - 90;
      let years = [];
      for (let index = current; index > diff; index--) {
        years.push(index);
      }
      return years;
    },
  },
};
</script>

<template>
  <b-field :label="label" :label-for="inputID">
    <b-select :id="inputID" v-model="model" placeholder="-">
      <option v-for="option in availableYears" :value="option" :key="option">{{
        option
      }}</option>
    </b-select>
  </b-field>
</template>
