<script>
export default {
  props: {
    title: {
      default: "Box",
      type: String,
    },
    entry: {
      default: null,
      type: Object,
    },
    editable: {
      default: false,
      type: Boolean,
    },
    editing: {
      default: false,
      type: Boolean,
    },
    hasSubmit: {
      default: false,
      type: Boolean,
    },
    canSubmit: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    hasEditableModal() {
      return !!this.$slots["editable_modal"];
    },
  },
  watch: {
    $route() {
      this.$emit("editing", false);
    },
  },
  methods: {
    close() {
      this.$emit("editing", false);
    },
  },
};
</script>

<template>
  <div class="listingBox" :class="{ editing: editing }">
    <div class="box__head">
      <div class="toggle--collapse" @click="$emit('toggle-collapse')">
        <b-icon icon="chevron-down" />
        <div class="box__title" v-if="title">{{ title }}</div>
      </div>
      <div class="right" v-if="editable">
        <b-button
          class="toggle--edit"
          :type="editing ? 'is-warning' : 'is-link'"
          rounded
          size="is-small"
          @click="$emit('editing', !editing)"
          v-text="editing ? 'Cerrar' : 'Editar'"
        />
      </div>
    </div>

    <div class="box__body">
      <slot />
    </div>

    <!-- <portal to="mudal" v-if="editing && hasEditableModal">
      <s-mudal :active="true" @close="close">
        <div class="editor__top">
          <div class="box__title" v-if="title">{{ title }}</div>
        </div>
        <div class="editor__content">
          <slot name="editable_modal" />
        </div>
        <div class="editor__bottom">
          <b-button
            type="is-primary"
            v-if="hasSubmit"
            :disabled="!canSubmit"
            @click="$emit('submit')"
          >
            Guardar
          </b-button>
        </div>
      </s-mudal>
    </portal> -->

    <s-mudal
      v-if="editing && hasEditableModal"
      customClass="listingEditorCard"
      :active="true"
      @close="close"
    >
      <div class="editor__top">
        <div class="box__title" v-if="title">{{ title }}</div>
      </div>
      <div class="editor__content">
        <slot name="editable_modal" />
      </div>
      <div class="editor__bottom">
        <b-button
          type="is-primary"
          v-if="hasSubmit"
          :disabled="!canSubmit"
          @click="$emit('submit')"
        >
          Guardar
        </b-button>
      </div>
    </s-mudal>
  </div>
</template>

<style lang="scss">
.listingEditorCard {
  .sitioMudal__content {
    background-color: #fff;
    border-radius: 0.5rem;
  }

  .editor__top,
  .editor__content,
  .editor__bottom {
    padding: 0.75rem 1.25rem;
  }

  .editor__top {
    border-bottom: 1px solid #ecf0f4;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .box__title {
    font-size: 0.9375rem;
    color: #0b9696;
    font-weight: bold;
    font-size: 1.125rem;
    color: #000;
  }
}
</style>
